<template>
  <div class="edit-address">
    <!-- 头部 -->
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <!-- 内容 -->
    <van-address-edit
      :area-list="areaList"
      :show-delete="showDel"
      show-set-default
      :is-saving="isSaving"
      :is-deleting="isDeleting"
      show-search-result
      :address-info="addressInfo"
      :tel-validator="checkTel"
      tel-maxlength="11"
      @save="onSave"
      @delete="onDelete"
      class="dark_card"
    />
  </div>
</template>

<script>
import { NavBar, AddressEdit } from "vant";
import areaList from "./area.js";
let userId = localStorage.getItem("customerId");
let accessToken = localStorage.getItem("token");

export default {
  data() {
    return {
      areaList: areaList,
      addressInfo: {},
      isSaving: false,
      isDeleting: false,
      title: null,
      showDel: false, // 是否显示删除按钮
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [AddressEdit.name]: AddressEdit,
  },
  created() {
    setTimeout(() => {
      this.dataInit();
    }, 1500);
    // this.getareaList();
  },
  mounted() {
    // setTimeout(() => {
    // }, 100);
  },
  methods: {
    //地址选择
    getareaList() {
      this.$api.my.getAllRegion().then((res) => {
        this.areaList = JSON.parse(res.data);
      });
    },

    // 初始回显值
    dataInit() {
      let address = this.$route.params.address;

      if (address) {
        this.title = "地址编辑";
        this.showDel = true;
      } else {
        this.showDel = false;
        return (this.title = "新增地址");
      }

      this.$api.my
        .readAddressSingle({
          userId,
          addressId: address.id,
        })
        .then((res) => {
          // console.log("获取地址", res.data);
          if (res.errno === 200) {
            this.addressInfo = res.data;

            // this.addressInfo = {
            //   address: "福建省福州市闽清县 1",
            //   areaCode: "350124",
            //   city: "福州市",
            //   consignee: "12",
            //   county: "闽清县",
            //   defaultAddress: 0,
            //   gmtCreate: 1642525304000,
            //   gmtUpdate: 1643283048000,
            //   id: 2239,
            //   isDefault: false,
            //   name: "12",
            //   phone: "13512315123",
            //   province: "福建省",
            //   tel: "13512315123",
            //   userId: 150823,
            // };

            this.addressInfo.name = res.data.consignee;

            this.addressInfo.tel = this.addressInfo.phone;
            // this.addressInfo.code = this.addressInfo.areaCode;

            this.addressInfo.addressDetail = this.addressInfo.address;
            // this.addressInfo.name = this.addressInfo.consignee;
            // this.addressInfo.name = this.addressInfo.consignee;

            if (this.addressInfo.defaultAddress === 1) {
              this.addressInfo.isDefault = true;
            } else {
              this.addressInfo.isDefault = false;
            }

            this.addressInfo.isDefault = this.addressInfo.isDefault == "1";
            // console.log(this.addressInfo);
          }
        });
    },
    checkTel(tel) {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (reg.test(tel)) {
        return true;
      } else {
        return false;
      }
    },
    // 返回
    onClickLeft() {
      this.$router.back(0);
    },
    // 保存+新增+修改
    onSave(item) {
      // return;
      if (item.name.length < 2 || item.name.length > 25) {
        this.$toast({
          message: "收货人姓名2-25个字符",
          duration: 1000,
        });
        return false;
      }
      this.isSaving = true;
      item.address = item.addressDetail;
      // 修改
      if (item.id) {
        this.$api.my
          .changeAddressListAddress({
            accessToken,
            userId,
            consignee: item.name,
            phone: item.tel,
            address: item.address,
            // defaultAddress: item.address,
            addressId: item.id,
            defaultAddress: item.isDefault ? "1" : "0",
            province: item.province,
            city: item.city,
            county: item.county,
            areaCode: item.areaCode,
          })
          .then((res) => {
            if (res.errno === 200) {
              this.isSaving = false;
              this.$router.back(0);
            } else {
              this.$toast.fail(res.message);
            }
          });
      } else {
        // 新增
        this.$api.my
          .addAddressListAddress({
            // accessToken,
            // name: item.name,
            // tel: item.tel,
            // address: item.address,
            // id: item.id,
            // isDefault: item.isDefault ? "1" : "0",
            // province: item.province,
            // city: item.city,
            // county: item.county,
            // areaCode: item.areaCode,
            accessToken,
            userId,
            consignee: item.name,
            phone: item.tel,
            address: item.address,
            // defaultAddress: item.address,
            defaultAddress: item.isDefault ? "1" : "0",
            province: item.province,
            city: item.city,
            county: item.county,
            areaCode: item.areaCode,
          })
          .then((res) => {
            if (res.errno === 200) {
              this.$router.back(0);
            } else {
              this.$toast.fail(res.message);
            }
          });
      }
    },
    // 删除
    onDelete() {
      this.isDeleting = true;
      this.$api.my
        .delAddressListAddress({
          addressId: this.addressInfo.id,
          userId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.isDeleting = false;
            this.$toast({
              message: "删除成功",
              onClose: () => {
                this.$router.back();
              },
            });
          } else {
            this.isDeleting = false;
            this.$toast.fail(res.message);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../less/element.less";
// @red: #c83f3f;
.edit-address {
  width: 100%;
  box-sizing: border-box;
  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    .van-icon {
      color: #373737;
    }
    .van-nav-bar__text {
      // color: @red;
    }
  }
}
</style>
<style lang='less'>
// @red: #c83f3f;
.edit-address {
  .van-button--danger {
    // background-color: @red;
    // border-color: @red;
    // letter-spacing: 2px;

    //  background: linear-gradient(0deg, #EE3A3A, #F46161);
     background: @ordinary_btn_color;
      border-radius: .1rem;
      font-size: .36rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .36rem;
  }
  .van-button--round{
      border-radius: .1rem;
      font-size: .36rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: .36rem;
  }
  .van-switch--on{
    background-color: @switch_on_color;
  }
}
.van-button--default{
  color: #7e7e7e;
  font-size: .36rem;
font-family: PingFang SC;
font-weight: 500;

}
.edit-address .van-button--danger {
  border: 1px solid @ordinary_btn_border_color;
}
</style>

